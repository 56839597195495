import { gql } from "@apollo/client";

export const COMPANY_REPORT = gql`
  query company($id: ID!, $filters: CompanyFiltersInput!) {
    company(id: $id) {
      data {
        id
        attributes {
          name
          issuerId
          ticker
          source
          name_en
          ISIN
          issuerId
          engagement
          analystName
          analysisDate
          activity
          analystSummary
          engagement
          eventsFlag
          flagExplanation
          countries(pagination: { limit: -1 }) {
            data {
              attributes {
                name
                isDeveloped
              }
            }
          }
          companyGradeHistory(
            pagination: { limit: -1 }
            filters: { grade: { ne: null }, date: { ne: null } }
          ) {
            grade
            date
            letterGrade
          }
          sdgActivities {
            id
            type
            involvement
            description
          }
          grade {
            grade
            letterGrade
            description
          }
          climateGrade {
            grade
            letterGrade
          }
          social {
            grade {
              grade
              description
            }
            issues(pagination: { limit: -1 }, sort: "issue.position:ASC") {
              description
              management
              risk
              issue {
                data {
                  attributes {
                    name
                    position
                    label
                  }
                }
              }
              subIssues(pagination: { limit: -1 }) {
                data {
                  attributes {
                    name
                    label
                  }
                }
              }
            }
            incidents(pagination: { limit: -1 }) {
              name
              description
              mitigation
              severity
              date
              mitigationDescription
              incidentCheckboxes {
                data {
                  attributes {
                    name
                    label
                  }
                }
              }
            }
          }
          environmental {
            grade {
              grade
              description
            }
            issues(pagination: { limit: -1 }, sort: "issue.position:ASC") {
              description
              management
              risk
              issue {
                data {
                  attributes {
                    name
                    position
                    label
                  }
                }
              }
              subIssues(pagination: { limit: -1 }) {
                data {
                  attributes {
                    name
                    label
                  }
                }
              }
            }
            incidents(pagination: { limit: -1 }) {
              name
              description
              mitigation
              severity
              date
              mitigationDescription
              incidentCheckboxes {
                data {
                  attributes {
                    name
                    label
                  }
                }
              }
            }
          }
          governance {
            grade {
              grade
              description
            }
            issues(pagination: { limit: -1 }, sort: "issue.position:ASC") {
              description
              management
              risk
              issue {
                data {
                  attributes {
                    name
                    position
                    label
                  }
                }
              }
              subIssues(pagination: { limit: -1 }) {
                data {
                  attributes {
                    name
                    label
                  }
                }
              }
            }
            incidents(pagination: { limit: -1 }) {
              name
              description
              mitigation
              severity
              date
              mitigationDescription
              incidentCheckboxes {
                data {
                  attributes {
                    name
                    label
                  }
                }
              }
            }
          }
          primarySector {
            data {
              attributes {
                name
                avgEsgGrade
                minEsgGrade
                maxEsgGrade
                environmentalSummary {
                  minGrade
                  maxGrade
                  avgGrade
                  description
                  relevance
                }
                socialSummary {
                  minGrade
                  maxGrade
                  avgGrade
                  description
                  relevance
                }
                governanceSummary {
                  minGrade
                  maxGrade
                  avgGrade
                  description
                  relevance
                }
                primaryCompanies(
                  filters: $filters
                  sort: "grade.grade:desc"
                  pagination: { limit: -1 }
                ) {
                  data {
                    id
                    attributes {
                      name
                      name_en
                      grade {
                        grade
                      }
                      environmental {
                        grade {
                          grade
                        }
                      }
                      social {
                        grade {
                          grade
                        }
                      }
                      governance {
                        grade {
                          grade
                        }
                      }
                      eventsFlag
                      flagExplanation
                      events {
                        data {
                          attributes {
                            severity
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          greeneyeSector {
            data {
              attributes {
                name
              }
            }
          }
          badActivities {
            id
            type
            description
            involvement
          }
          indexes {
            data {
              id
              attributes {
                name
              }
            }
          }
          eventsFlag
          flagExplanation
          companyHistory {
            percentageOfEnablingActivities
            percentageOfDelayingActivities
          }
          events(sort: "date:desc") {
            data {
              id
              attributes {
                affectedArea
                severity
                response
                description
                date
              }
            }
          }
        }
      }
    }
  }
`;

export const COMPANIES_CSV = gql`
  query myCompanies(
    $filters: MyCompanyFiltersInput!
    $pagination: PaginationArg!
  ) {
    myCompanies(filters: $filters, pagination: $pagination) {
      data {
        attributes {
          name
          ticker
          ISIN
          name_en
          issuerId
          analystSummary
          grade {
            grade
            percentileInSector
            percentileInGeneral
            gradeRelativeToSectorAverage
            accGradesWithoutFactor
            letterGrade
          }
          primarySector {
            data {
              attributes {
                name
              }
            }
          }
          securities(pagination: { limit: -1 }, sort: "name:asc") {
            data {
              attributes {
                taseId
              }
            }
          }
          social {
            grade {
              grade
              gradeRelativeToSectorAverage
            }
          }
          governance {
            grade {
              grade
              gradeRelativeToSectorAverage
            }
          }
          environmental {
            grade {
              grade
              gradeRelativeToSectorAverage
            }
          }
          badActivities {
            type
          }
          sdgActivities {
            type
          }
          eventsFlag
          flagExplanation
          events(sort: "date:desc") {
            data {
              attributes {
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const USER_COMPANIES = gql`
  query myCompanies(
    $filters: MyCompanyFiltersInput!
    $pagination: PaginationArg!
  ) {
    myCompanies(filters: $filters, pagination: $pagination, sort: "name:asc") {
      count
      data {
        id
        attributes {
          name
          issuerId
          ticker
          source
          ISIN
          name_en
          grade {
            grade
            letterGrade
          }
          primarySector {
            data {
              attributes {
                name
              }
            }
          }
          greeneyeSector {
            data {
              attributes {
                name
              }
            }
          }
          social {
            grade {
              grade
            }
          }
          governance {
            grade {
              grade
            }
          }
          environmental {
            grade {
              grade
            }
          }
          badActivities {
            id
          }
          sdgActivities {
            id
          }
          eventsFlag
          flagExplanation
          events {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const COMPANIES_WITH_ISSUES = gql`
  query myCompaniesByIssues(
    $filters: MyCompanyFiltersInput!
    $pagination: PaginationArg!
  ) {
    myCompanies(filters: $filters, pagination: $pagination, sort: "name:asc") {
      data {
        id
        attributes {
          name
          ticker
          source
          ISIN
          issuerId
          analystSummary
          name_en
          grade {
            grade
            percentileInSector
            percentileInGeneral
            gradeRelativeToSectorAverage
            accGradesWithoutFactor
            letterGrade
          }
          primarySector {
            data {
              attributes {
                name
              }
            }
          }
          securities(pagination: { limit: -1 }, sort: "name:asc") {
            data {
              attributes {
                taseId
              }
            }
          }
          social {
            grade {
              grade
              gradeRelativeToSectorAverage
            }
          }
          governance {
            grade {
              grade
              gradeRelativeToSectorAverage
            }
          }
          environmental {
            grade {
              grade
              gradeRelativeToSectorAverage
            }
          }
          badActivities {
            type
            involvement
          }
          sdgActivities {
            type
            involvement
          }
          eventsFlag
          flagExplanation
          events(sort: "date:desc") {
            data {
              attributes {
                description
              }
            }
          }
          social {
            issues(pagination: { limit: -1 }) {
              management
              risk
              issue {
                data {
                  attributes {
                    label
                  }
                }
              }
            }
          }
          governance {
            issues(pagination: { limit: -1 }) {
              management
              risk
              issue {
                data {
                  attributes {
                    label
                  }
                }
              }
            }
          }
          environmental {
            issues(pagination: { limit: -1 }) {
              management
              risk
              issue {
                data {
                  attributes {
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ME = gql`
  query me {
    me {
      id
      username
      email
      letterGrading
      hasFundsFeature
      role {
        name
      }
      orgId
      hasClimateGrade
      hasCompanyGraph
    }
  }
`;

export const CONFIG = gql`
  query config {
    config {
      data {
        attributes {
          disclaimer
          featureIssue {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const ISSUES = gql`
  query issues {
    issues(pagination: { limit: -1 }) {
      data {
        attributes {
          name
          label
          position
          area
          subIssues {
            data {
              attributes {
                name
                label
              }
            }
          }
        }
      }
    }
  }
`;

export const INDICES = gql`
  query indices($filters: IndexFiltersInput!) {
    indices(pagination: { limit: -1 }, filters: $filters) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const SECTORS = gql`
  query sectors($filters: SectorFiltersInput!) {
    sectors(pagination: { limit: -1 }, filters: $filters) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const GRADE_CONFIG = gql`
  query gradeToLetterConfig($publicationState: PublicationState!) {
    gradeToLetterConfig(publicationState: $publicationState) {
      data {
        attributes {
          config {
            penalty
            type
            ranges {
              min
              max
              grade
            }
          }
        }
      }
    }
  }
`;

export const FUNDS = gql`
  query fundsFilteredQuery(
    $filters: FundFilterInput
    $pagination: PaginationArg!
  ) {
    fundsFilteredQuery(
      filters: $filters
      pagination: $pagination
      sort: "name:asc"
    ) {
      count
      data {
        id
        attributes {
          fundName
          fundId
          fundMarketCap
          fundAssessedPercentage
          fundWeightedGrade
          fundDate
          percentAssessedByGreeneye
          badActivitiesPercent
          percentOfRedFlagSecurities
          sdgPercent
          assets(pagination: { limit: -1 }) {
            assetName
            assetType
            assetId
            amount
            valueInNis
            percentOfTheFund
            ticker
            weight
            grade
            weightedGrade
            normalizedWeight
            company
            isRedFlag
          }
          fundHistory(pagination: { limit: -1 }) {
            fundDate
            weightedGrade
          }
          benchmark {
            data {
              attributes {
                fundName
                fundWeightedGrade
                percentAssessedByGreeneye
                badActivitiesPercent
                percentOfRedFlagSecurities
                sdgPercent
                fundHistory(pagination: { limit: -1 }) {
                  fundDate
                  weightedGrade
                }
                assets(pagination: { limit: -1 }) {
                  assetName
                  assetType
                  assetId
                  amount
                  valueInNis
                  percentOfTheFund
                  ticker
                  weight
                  grade
                  weightedGrade
                  normalizedWeight
                  company
                  isRedFlag
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const COMPANY_DATA = gql`
  query companyDataQuery($filters: CompanyDataFilterInput) {
    companyDataQuery(filters: $filters) {
      data {
        id
        attributes {
          issuerId
          companyData(pagination: { limit: -1 }) {
            yearOne
            yearTwo
            yearOneData
            yearTwoData
            yearOneActivity
            yearTwoActivity
          }
          percentageData(pagination: { limit: -1 }) {
            total
            percent
            label
            womenCount
          }
          companyHistory {
            yearOne
            yearTwo
            yearOneCount
            yearTwoCount
            yearOneRevenue
            yearTwoRevenue
            percentageOfEnablingActivities
            percentageOfDelayingActivities
          }
        }
      }
    }
  }
`;
