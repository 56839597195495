import {
  Activity,
  Asset,
  Benchmark,
  Company,
  CompanyData,
  CompanyReport,
  CompanyWithIssues,
  CountryEntity,
  EventEntity,
  Fund,
  Incident,
  IssueEntity,
  nameLabelPair,
  SectorCompany,
  TextValuePair,
} from "./types";

export const formatCompanies = (companies: any): Company[] => {
  return (
    companies?.map(
      (d: any): Company => ({
        id: d.id,
        issuerId: d.attributes?.issuerId,
        name: d.attributes?.name,
        name_en: d.attributes?.name_en,
        ticker: d.attributes?.ticker,
        source: d.attributes?.source,
        isin: d.attributes.ISIN,
        analystSummary: d.attributes?.analystSummary,
        securities: d.attributes?.securities?.data?.map((s: any) =>
          String(s.attributes?.taseId)
        ),
        primarySector: {
          name: d.attributes?.primarySector?.data?.attributes?.name,
        },
        greeneyeSector: {
          name: d.attributes?.greeneyeSector?.data?.attributes?.name,
        },
        esgGrade: {
          grade: d.attributes?.grade?.grade,
          average: d.attributes?.primarySector?.data?.attributes?.avgEsgGrade,
          gradeRelativeToSectorAverage:
            d.attributes?.grade?.gradeRelativeToSectorAverage,
          percentileInSector: d.attributes?.grade?.percentileInSector,
          percentileInGeneral: d.attributes?.grade?.percentileInGeneral,
          accGradesWithoutFactor: d.attributes?.grade?.accGradesWithoutFactor,
          letterGrade: d.attributes?.grade?.letterGrade,
        },
        environmental: {
          grade: d.attributes?.environmental?.grade?.grade,
          gradeRelativeToSectorAverage:
            d.attributes?.environmental?.grade?.gradeRelativeToSectorAverage,
          average:
            d.attributes?.primarySector?.data?.attributes?.environmentalSummary
              ?.avgGrade,
        },
        social: {
          grade: d.attributes?.social?.grade?.grade,
          gradeRelativeToSectorAverage:
            d.attributes?.social?.grade?.gradeRelativeToSectorAverage,
          average:
            d.attributes?.primarySector?.data?.attributes?.socialSummary
              ?.avgGrade,
        },
        governance: {
          grade: d.attributes?.governance?.grade?.grade,
          gradeRelativeToSectorAverage:
            d.attributes?.governance?.grade?.gradeRelativeToSectorAverage,
          average:
            d.attributes?.primarySector?.data?.attributes?.governanceSummary
              ?.avgGrade,
        },
        badActivities: d.attributes?.badActivities?.map(
          (i: any): Activity => ({
            id: i.id,
            type: i.type,
            involvement: i.involvement,
          })
        ),
        sdgActivities: d.attributes?.sdgActivities?.map(
          (i: any): Activity => ({
            id: i.id,
            type: i.type,
            involvement: i.involvement,
          })
        ),
        eventsFlag: d.attributes.eventsFlag,
        flagExplanation: d.attributes.flagExplanation,
        events: d.attributes?.events?.data?.map(
          (i: any): EventEntity => ({
            id: i.id,
            description: i.attributes?.description,
          })
        ),
        indexes: d?.attributes?.indexes?.data?.map(
          (i: any): TextValuePair<string> => ({
            text: i.attributes.name,
            value: i.id,
          })
        ),
      })
    ) || []
  );
};

export const formatCompanyData = (data: any): CompanyData => {
  return {
    percentageOfDelayingActivities:
      data?.companyHistory?.percentageOfDelayingActivities || 0,
    percentageOfEnablingActivities:
      data?.companyHistory?.percentageOfEnablingActivities || 0,
  };
};

export const formatCompanyReport = (
  data: any,
  id: string,
  selectedDictionary: any,
  selectedLanguage: any
): CompanyReport => {
  return (
    {
      id: id,
      name: selectedLanguage === "en" ? data?.name_en : data?.name,
      name_en: data?.name_en,
      ticker: data?.ticker,
      source: data?.source,
      issuerId: data?.issuerId,
      isin: data?.ISIN,
      activity: data?.activity,
      analysisDate: data?.analysisDate,
      analystName: data?.analystName,
      analystSummary: data?.analystSummary,
      engagement: data?.engagement,
      countries: data?.countries?.data?.map(
        (i: any): CountryEntity => ({
          name: i.attributes?.name,
          isDeveloped: i.attributes?.isDeveloped,
        })
      ),
      primarySector: { name: data?.primarySector?.data?.attributes?.name },
      greeneyeSector: { name: data?.greeneyeSector?.data?.attributes?.name },
      environmental: {
        name: selectedDictionary.formattersCell1,
        grade: data?.environmental?.grade?.grade,
        average:
          data?.primarySector?.data?.attributes?.environmentalSummary?.avgGrade,
        min: data?.primarySector?.data?.attributes?.environmentalSummary
          ?.minGrade,
        max: data?.primarySector?.data?.attributes?.environmentalSummary
          ?.maxGrade,
        relevance:
          data?.primarySector?.data?.attributes?.environmentalSummary
            ?.relevance,
        sectorDescription:
          data?.primarySector?.data?.attributes?.environmentalSummary
            ?.description,
        companyDescription: data?.environmental?.grade?.description,
        issues: formatIssuesForCompanyReport(
          data?.environmental?.issues,
          selectedDictionary.formattersCell1
        ),
        incidents: formatIncidents(data?.environmental?.incidents),
      },
      social: {
        name: selectedDictionary.formattersCell2,
        grade: data?.social?.grade?.grade,
        average: data?.primarySector?.data?.attributes?.socialSummary?.avgGrade,
        min: data?.primarySector?.data?.attributes?.socialSummary?.minGrade,
        max: data?.primarySector?.data?.attributes?.socialSummary?.maxGrade,
        relevance:
          data?.primarySector?.data?.attributes?.socialSummary?.relevance,
        sectorDescription:
          data?.primarySector?.data?.attributes?.socialSummary?.description,
        companyDescription: data?.social?.grade?.description,
        issues: formatIssuesForCompanyReport(
          data?.social?.issues,
          selectedDictionary.formattersCell2
        ),
        incidents: formatIncidents(data?.social?.incidents),
      },
      governance: {
        name: selectedDictionary.formattersCell3,
        grade: data?.governance?.grade?.grade,
        average:
          data?.primarySector?.data?.attributes?.governanceSummary?.avgGrade,
        min: data?.primarySector?.data?.attributes?.governanceSummary?.minGrade,
        max: data?.primarySector?.data?.attributes?.governanceSummary?.maxGrade,
        relevance:
          data?.primarySector?.data?.attributes?.governanceSummary?.relevance,
        sectorDescription:
          data?.primarySector?.data?.attributes?.governanceSummary?.description,
        companyDescription: data?.governance?.grade?.description,
        issues: formatIssuesForCompanyReport(
          data?.governance?.issues,
          selectedDictionary.formattersCell3
        ),
        incidents: formatIncidents(data?.governance?.incidents),
      },
      esgGrade: {
        letterGrade: data?.grade?.letterGrade,
        grade: data?.grade?.grade,
        average: data?.primarySector?.data?.attributes?.avgEsgGrade,
        min: data?.primarySector?.data?.attributes?.minEsgGrade,
        max: data?.primarySector?.data?.attributes?.maxEsgGrade,
        comment: data?.grade?.description,
      },
      climateGrade: {
        letterGrade: data?.climateGrade?.letterGrade,
        grade: data?.climateGrade?.grade,
      },
      companyGradeHistory: data?.companyGradeHistory,
      eventsFlag: data?.eventsFlag,
      flagExplanation: data?.flagExplanation,
      events: data?.events?.data?.map(
        (i: any): EventEntity => ({
          id: i.id,
          affectedArea: i.attributes.affectedArea,
          severity: i.attributes.severity,
          response: i.attributes.response,
          description: i.attributes.description,
          isRecurring: i.attributes.isRecurring,
          date: i.attributes.date,
        })
      ),
      sdgActivities: data?.sdgActivities?.map(
        (i: any): Activity => ({
          id: i.id,
          type: i.type,
          description: i.description,
          involvement: i.involvement,
        })
      ),
      badActivities: data?.badActivities?.map(
        (i: any): Activity => ({
          id: i.id,
          type: i.type,
          description: i.description,
          involvement: i.involvement,
        })
      ),
      sectorCompanies:
        data?.primarySector?.data?.attributes?.primaryCompanies?.data?.map(
          (i: any): SectorCompany => ({
            id: i.id,
            name:
              selectedLanguage === "en"
                ? i.attributes?.name_en
                : i.attributes?.name,
            grade: i.attributes?.grade?.grade,
            environmentalGrade: i.attributes?.environmental?.grade?.grade,
            socialGrade: i.attributes?.social?.grade?.grade,
            governanceGrade: i.attributes?.governance?.grade?.grade,
            events: i.attributes?.events?.data?.length > 0,
            eventsFlag: i.attributes?.eventsFlag,
          })
        ),
    } || []
  );
};

export const formatCompanyIssues = (data: any): CompanyWithIssues[] => {
  if (!data) {
    return [];
  }
  const companies: CompanyWithIssues[] = [];
  for (const d of data) {
    const companyIssues: CompanyWithIssues = {
      id: d.id,
      issuerId: d.attributes?.issuerId,
      name: d.attributes?.name,
      name_en: d.attributes?.name_en,
      issues: [],
      isin: d.attributes?.ISIN,
      ticker: d.attributes?.ticker,
      analystSummary: d.attributes?.analystSummary,
      primarySector: {
        name: d.attributes?.primarySector?.data?.attributes?.name,
      },
      securities: d.attributes?.securities?.data?.map((s: any) =>
        String(s.attributes?.taseId)
      ),
      esgGrade: {
        grade: d.attributes?.grade?.grade,
        average: d.attributes?.primarySector?.data?.attributes?.avgEsgGrade,
        gradeRelativeToSectorAverage:
          d.attributes?.grade?.gradeRelativeToSectorAverage,
        percentileInSector: d.attributes?.grade?.percentileInSector,
        percentileInGeneral: d.attributes?.grade?.percentileInGeneral,
        accGradesWithoutFactor: d.attributes?.grade?.accGradesWithoutFactor,
        letterGrade: d.attributes?.grade?.letterGrade,
      },
      environmental: {
        grade: d.attributes?.environmental?.grade?.grade,
        gradeRelativeToSectorAverage:
          d.attributes?.environmental?.grade?.gradeRelativeToSectorAverage,
        average:
          d.attributes?.primarySector?.data?.attributes?.environmentalSummary
            ?.avgGrade,
      },
      social: {
        grade: d.attributes?.social?.grade?.grade,
        gradeRelativeToSectorAverage:
          d.attributes?.social?.grade?.gradeRelativeToSectorAverage,
        average:
          d.attributes?.primarySector?.data?.attributes?.socialSummary
            ?.avgGrade,
      },
      governance: {
        grade: d.attributes?.governance?.grade?.grade,
        gradeRelativeToSectorAverage:
          d.attributes?.governance?.grade?.gradeRelativeToSectorAverage,
        average:
          d.attributes?.primarySector?.data?.attributes?.governanceSummary
            ?.avgGrade,
      },
      badActivities: d.attributes?.badActivities?.map(
        (i: any): Activity => ({
          id: i.id,
          type: i.type,
          involvement: i.involvement,
        })
      ),
      sdgActivities: d.attributes?.sdgActivities?.map(
        (i: any): Activity => ({
          id: i.id,
          type: i.type,
          involvement: i.involvement,
        })
      ),
      eventsFlag: d.attributes.eventsFlag,
      flagExplanation: d.attributes.flagExplanation,
      events: d.attributes?.events?.data?.map(
        (i: any): EventEntity => ({
          id: i.id,
          description: i.attributes?.description,
        })
      ),
    };
    if (d.attributes?.social?.issues) {
      companyIssues.issues.push(
        ...d.attributes?.social?.issues?.map((i: any) => ({
          label: i.issue?.data?.attributes?.label,
          risk: i.risk,
          management: i.management,
        }))
      );
    }
    if (d.attributes?.environmental?.issues) {
      companyIssues.issues.push(
        ...d.attributes?.environmental?.issues?.map((i: any) => ({
          label: i.issue?.data?.attributes?.label,
          risk: i.risk,
          management: i.management,
        }))
      );
    }
    if (d.attributes?.governance?.issues) {
      companyIssues.issues.push(
        ...d.attributes?.governance?.issues?.map((i: any) => ({
          label: i.issue?.data?.attributes?.label,
          risk: i.risk,
          management: i.management,
        }))
      );
    }
    companies.push(companyIssues);
  }
  return companies;
};

export const formatIssues = (data: any): IssueEntity[] => {
  return data?.map(
    (d: any): IssueEntity => ({
      area: d.attributes?.area,
      name: d.attributes?.name,
      label: d.attributes?.label,
      position: d.attributes?.position,
      management: 0,
      risk: 0,
      subIssues: d.attributes?.subIssues?.data?.map(
        (i: any): TextValuePair<string> => ({
          text: i.attributes?.name,
          value: i.attributes?.label,
        })
      ),
    })
  );
};
const formatIssuesForCompanyReport = (
  data: any,
  textArea: string
): IssueEntity[] => {
  return data?.map(
    (d: any): IssueEntity => ({
      area: textArea,
      name: d.issue?.data?.attributes?.name,
      label: d.issue?.data?.attributes?.label,
      description: d.description,
      management: d.management,
      position: d.position,
      risk: d.risk,
      subIssues: d.subIssues?.data?.map(
        (s: any): TextValuePair<string> => ({
          text: s.attributes?.name,
          value: s.attributes?.label,
        })
      ),
    })
  );
};

const formatIncidents = (data: any): Incident[] => {
  return data?.map(
    (i: any): Incident => ({
      mitigation: i.mitigation,
      description: i.description,
      severity: i.severity,
      name: i.name,
      date: i.date,
      mitigationDescription: i.mitigationDescription,
      incidentCheckboxes: i.incidentCheckboxes?.data?.map(
        (c: any): nameLabelPair => ({
          name: c.attributes?.name,
          label: c.attributes?.label,
        })
      ),
    })
  );
};

const severityMatching: any = {
  "2": "נמוכה",
  "4": "בינונית-נמוכה",
  "6": "בינונית",
  "8": "גבוהה",
  "10": "גבוהה מאוד",
};
const severityMatchingEn: any = {
  "2": "Low",
  "4": "Medium-Low",
  "6": "Medium",
  "8": "High",
  "10": "Very High",
};

const mitigationMatching: any = {
  "1": "לא נמצאה / בסיסית מאוד",
  "3": "בסיסית",
  "5": "בינונית",
  "7": "מתקדמת",
  "9": "מתקדמת מאוד",
};
const mitigationMatchingEn: any = {
  "1": "Not Found / Very Basic",
  "3": "Basic",
  "5": "Medium",
  "7": "Advancing",
  "9": "Very Advanced",
};

export const matchValuesWithText = (
  value: any,
  type: string,
  selectedLanguage: any
): string => {
  const selectedSeverityMatching =
    selectedLanguage === "en" ? severityMatchingEn : severityMatching;
  const selectedMitigationMatching =
    selectedLanguage === "en" ? mitigationMatchingEn : mitigationMatching;
  switch (type) {
    case "severity":
      return value in selectedSeverityMatching
        ? selectedSeverityMatching[value]
        : value;
    case "mitigation":
      return value in selectedMitigationMatching
        ? selectedMitigationMatching[value]
        : value;
    default:
      return value;
  }
};

const formatAssets = (assets: any[]): Asset[] => {
  const formattedAssets = assets.map((asset) => ({
    assetId: asset.assetId,
    assetName: asset?.assetName,
    assetType: asset?.assetType,
    amount: asset?.amount,
    valueInNis: asset?.valueInNis,
    percentOfTheFund: asset?.percentOfTheFund,
    isAssessed: asset?.isAssessed,
    ticker: asset?.ticker,
    weight: asset?.weight,
    grade: asset?.grade,
    normalizedWeight: asset?.normalizedWeight,
    weightedGrade: asset?.weightedGrade,
    company: asset?.company,
    isRedFlag: asset?.isRedFlag,
  }));
  return formattedAssets;
};
const formatBenchmark = (benchmark: any): Benchmark => {
  return {
    fundName: benchmark.data?.attributes?.fundName,
    fundWeightedGrade: benchmark.data?.attributes?.fundWeightedGrade,
    percentAssessedByGreeneye:
      benchmark.data?.attributes?.percentAssessedByGreeneye,
    fundHistory: benchmark.data?.attributes?.fundHistory,
    sdgPercent: benchmark.data?.attributes?.sdgPercent,
    percentOfRedFlagSecurities:
      benchmark.data?.attributes?.percentOfRedFlagSecurities,
    badActivitiesPercent: benchmark.data?.attributes?.badActivitiesPercent,
    assets: formatAssets(benchmark?.data?.attributes?.assets || []),
  };
};

export const formatFunds = (funds: any[]): Fund[] => {
  const formattedFunds = funds.map((fund) => ({
    fundId: fund.attributes?.fundId,
    fundName: fund.attributes?.fundName,
    fundMarketCap: fund.attributes?.fundMarketCap,
    fundAssessedPercentage: fund.attributes?.fundAssessedPercentage,
    fundWeightedGrade: fund.attributes?.fundWeightedGrade,
    fundDate: fund.attributes?.fundDate,
    percentAssessedByGreeneye: fund.attributes?.percentAssessedByGreeneye,
    fundHistory: fund.attributes?.fundHistory,
    sdgPercent: fund.attributes?.sdgPercent,
    percentOfRedFlagSecurities: fund.attributes?.percentOfRedFlagSecurities,
    badActivitiesPercent: fund.attributes?.badActivitiesPercent,
    benchmark: formatBenchmark(fund?.attributes?.benchmark),
    assets: formatAssets(fund.attributes?.assets || []),
  }));
  return formattedFunds;
};
